import React from 'react'
import ErrorMessage from './ErrorMessage'
import LoadingIndicator from './LoadingIndicator'

export type ErrorOrLoadingIndicatorProps = {
    children: React.ReactNode
    isLoading?: boolean
    error?: Error | string | null
}

const ErrorOrLoadingIndicator = ({
    children,
    isLoading = false,
    error = null,
}: ErrorOrLoadingIndicatorProps) => {
    if (error) {
        return <ErrorMessage error={error} />
    }

    if (isLoading) {
        return <LoadingIndicator />
    }

    return <div>{children}</div>
}

export default ErrorOrLoadingIndicator
