import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import { UserDataDto, UsersInGroupDto } from '../../types/dtoTypes'
import {
    SetUserCompaniesFailure,
    SetUserCompaniesRequest,
    SetUserCompaniesSuccess,
} from '../actionCreators/adminSetUserCompaniesActionCreators'
import {
    SetUserEnabledFailure,
    SetUserEnabledRequest,
    SetUserEnabledSuccess,
} from '../actionCreators/adminSetUserEnabledActionCreators'
import {
    SetUserGroupFailure,
    SetUserGroupRequest,
    SetUserGroupSuccess,
} from '../actionCreators/adminSetUserGroupActionCreators'
import {
    GetUserListDataFailure,
    GetUserListDataRequest,
    GetUserListDataSuccess,
} from '../actionCreators/adminUserListActionCreators'
import {
    SetUserCompaniesAction,
    SET_USER_COMPANIES,
} from '../actionTypes/adminSetUserCompaniesActionTypes'
import {
    SetUserEnabledAction,
    SET_USER_ENABLED,
} from '../actionTypes/adminSetUserEnabledActionTypes'
import {
    SetUserGroupAction,
    SET_USER_GROUP,
} from '../actionTypes/adminSetUserGroupActionTypes'
import { GET_USER_LIST_DATA } from '../actionTypes/adminUserListActionTypes'
import {
    fetchUserListData,
    setUserCompanies,
    setUserEnabled,
    setUserGroup,
} from '../services/adminService'

function* onLoadUserListData() {
    try {
        yield put(GetUserListDataRequest())

        const users: UsersInGroupDto[] = yield call(fetchUserListData)
        yield put(GetUserListDataSuccess(users))

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error)
        yield put(GetUserListDataFailure(error.response))
    }
}

function* onSetUserCompanies({ username, companies }: SetUserCompaniesAction) {
    try {
        yield put(SetUserCompaniesRequest(username, companies))

        const user: UserDataDto = yield call(
            setUserCompanies,
            username,
            companies
        )
        yield put(SetUserCompaniesSuccess(user))

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error)
        yield put(SetUserCompaniesFailure(error.response))
    }
}

function* onSetUserGroup({ username, group }: SetUserGroupAction) {
    try {
        yield put(SetUserGroupRequest(username, group))

        const user: UserDataDto = yield call(setUserGroup, username, group)
        yield put(SetUserGroupSuccess(user))

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error)
        yield put(SetUserGroupFailure(error.response))
    }
}

function* onSetUserEnabled({ username, enabled }: SetUserEnabledAction) {
    try {
        yield put(SetUserEnabledRequest(username, enabled))

        const user: UserDataDto = yield call(setUserEnabled, username, enabled)
        yield put(SetUserEnabledSuccess(user))

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error)
        yield put(SetUserEnabledFailure(error.response))
    }
}

function* watchOnLoadUserList() {
    yield takeLatest(GET_USER_LIST_DATA, onLoadUserListData)
}

function* watchOnSetUserCompanies() {
    yield takeLatest(SET_USER_COMPANIES, onSetUserCompanies)
}

function* watchOnSetUserGroup() {
    yield takeLatest(SET_USER_GROUP, onSetUserGroup)
}

function* watchOnSetUserEnabled() {
    yield takeLatest(SET_USER_ENABLED, onSetUserEnabled)
}

export default function* adminSaga() {
    yield all([
        fork(watchOnLoadUserList),
        fork(watchOnSetUserCompanies),
        fork(watchOnSetUserGroup),
        fork(watchOnSetUserEnabled),
    ])
}
