import {
    Calculation,
    CoveringLetter,
    ExpressOrder,
    Invoice,
} from '../futursoft/generated/sales'

export enum UserRole {
    UNKNOWN = 0,
    USER = 10,
    ADMIN = 99,
}

/**
 * This is what is shown to all users
 */
export type UserDto = {
    role: UserRole
    // Refers actually to customerNr
    companies: number[]
}

export type ListBaseDto = {
    totalPageCount: number
}

export type CoveringLetterListDto = ListBaseDto & {
    data: CoveringLetter[]
}

export type InvoiceListDto = ListBaseDto & {
    data: Invoice[]
}

export type CalculationListDto = ListBaseDto & {
    data: Calculation[]
}

export type ExpressOrderListDto = ListBaseDto & {
    data: ExpressOrder[]
}

/**
 * This is what is shown to admin users only
 */
export type UserDataDto = {
    companies: number[]
    email: string
    enabled: boolean
    userStatus: string
    username: string
    groupName: string
}

export type UsersInGroupDto = {
    groupName: string
    users: UserDataDto[]
}
