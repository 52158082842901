import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-locize-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'fi',
        defaultNS: 'en_Dashboard',
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
        backend: {
            projectId: '45d337f9-1b24-4bc7-8652-4be20a398969',
            referenceLng: 'fi',
        },
        detection: {
            order: ['querystring', 'localStorage'],
            lookupLocalStorage: 'i18nextLng',
            caches: ['localStorage'],
        },
    })

export default i18n
