import { PaginatedRequest, PaginatedRequestWithTtl } from '../types/stateTypes'

export const arePaginatedRequestsSimilar = (
    firstRequest: PaginatedRequest,
    secondRequest: PaginatedRequest
) =>
    firstRequest.customerNr === secondRequest.customerNr &&
    firstRequest.pageNumber === secondRequest.pageNumber &&
    firstRequest.pageSize === secondRequest.pageSize &&
    firstRequest.start === secondRequest.start &&
    firstRequest.end === secondRequest.end

export const findSimilarRequest = (
    currentRequest: PaginatedRequest,
    oldRequests: PaginatedRequestWithTtl[]
) =>
    oldRequests.find((request) =>
        arePaginatedRequestsSimilar(currentRequest, request)
    )

export const removeEmpty = (data: object): object =>
    Object.fromEntries(Object.entries(data).filter(([, v]) => v != null))
