import {
    ExpressOrderWithTtl,
    PaginatedRequest,
    PaginatedRequestWithTtl,
} from '../../types/stateTypes'
import {
    GetExpressOrderListDataAction,
    GetExpressOrderListDataFailureAction,
    GetExpressOrderListDataRequestAction,
    GetExpressOrderListDataSuccessAction,
    GET_EXPRESS_ORDER_LIST_DATA,
    GET_EXPRESS_ORDER_LIST_DATA_FAILURE,
    GET_EXPRESS_ORDER_LIST_DATA_REQUEST,
    GET_EXPRESS_ORDER_LIST_DATA_SUCCESS,
} from '../actionTypes/expressOrderListActionTypes'

export function getExpressOrderListData(
    request: PaginatedRequest
): GetExpressOrderListDataAction {
    return {
        type: GET_EXPRESS_ORDER_LIST_DATA,
        request,
    }
}

export function getExpressOrderListDataRequest(
    request: PaginatedRequest
): GetExpressOrderListDataRequestAction {
    return {
        type: GET_EXPRESS_ORDER_LIST_DATA_REQUEST,
        request,
    }
}

export function getExpressOrderListDataSuccess(
    expressOrders: ExpressOrderWithTtl[],
    request: PaginatedRequestWithTtl
): GetExpressOrderListDataSuccessAction {
    return {
        type: GET_EXPRESS_ORDER_LIST_DATA_SUCCESS,
        expressOrders,
        request,
    }
}

export function getExpressOrderListDataFailure(
    error: Error | string
): GetExpressOrderListDataFailureAction {
    return {
        type: GET_EXPRESS_ORDER_LIST_DATA_FAILURE,
        error,
    }
}
