import { put, call, takeLatest, all, fork, select } from 'redux-saga/effects'
import { Invoice } from '../../futursoft/generated/sales'
import { InvoiceListDto } from '../../types/dtoTypes'
import { InvoiceWithTtl, PaginatedRequestWithTtl } from '../../types/stateTypes'

import { shouldDataBeUpdated, DataType } from '../../utils/date'
import { findSimilarRequest } from '../../utils/request'
import {
    getInvoiceDataFailure,
    getInvoiceDataRequest,
    getInvoiceDataSuccess,
} from '../actionCreators/invoiceActionCreators'
import {
    getInvoiceListDataFailure,
    getInvoiceListDataRequest,
    getInvoiceListDataSuccess,
} from '../actionCreators/invoiceListActionCreators'
import {
    GET_INVOICE_DATA,
    GetInvoiceDataAction,
} from '../actionTypes/invoiceActionTypes'
import {
    GetInvoiceListDataAction,
    GET_INVOICE_LIST_DATA,
} from '../actionTypes/invoiceListActionTypes'
import { AppState } from '../reducers/rootReducer'
import { fetchInvoice, fetchInvoiceList } from '../services/invoiceService'

export const getInvoiceListRequests = (
    state: AppState
): PaginatedRequestWithTtl[] => state.invoice.requests || []
export const getInvoice = (
    state: AppState,
    invoiceNumber: number
): InvoiceWithTtl | undefined =>
    state.invoice.data.find((invoice) => invoice.invoiceNr === invoiceNumber)

function* onLoadInvoiceListData({ request }: GetInvoiceListDataAction) {
    try {
        const oldRequests: PaginatedRequestWithTtl[] = yield select(
            getInvoiceListRequests
        )
        const lastSimilarRequest = findSimilarRequest(request, oldRequests)

        if (
            !shouldDataBeUpdated(
                DataType.InvoiceData,
                lastSimilarRequest?.updated
            )
        ) {
            return
        }
        yield put(getInvoiceListDataRequest(request))

        const invoiceList: InvoiceListDto = yield call(
            fetchInvoiceList,
            request
        )

        const now = new Date()
        const invoicesWithTtl: InvoiceWithTtl[] = invoiceList.data.map(
            (invoice) => ({
                ...invoice,
                updated: now,
            })
        )
        yield put(
            getInvoiceListDataSuccess(invoicesWithTtl, {
                ...request,
                updated: now,
            })
        )

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error)
        yield put(getInvoiceListDataFailure(error.response))
    }
}

function* onLoadInvoiceData({ invoiceNumber }: GetInvoiceDataAction) {
    try {
        const cachedInvoice: InvoiceWithTtl = yield select(
            getInvoice,
            invoiceNumber
        )

        if (
            !shouldDataBeUpdated(DataType.InvoiceData, cachedInvoice?.updated)
        ) {
            return
        }

        yield put(getInvoiceDataRequest(invoiceNumber))

        const invoiceFromBackend: Invoice = yield call(
            fetchInvoice,
            invoiceNumber
        )

        const now = new Date()
        const invoiceWithTtl: InvoiceWithTtl = {
            ...invoiceFromBackend,
            updated: now,
        }
        yield put(getInvoiceDataSuccess(invoiceWithTtl, invoiceNumber))

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error)
        yield put(getInvoiceDataFailure(error.response))
    }
}

function* watchOnLoadInvoiceList() {
    yield takeLatest(GET_INVOICE_LIST_DATA, onLoadInvoiceListData)
}

function* watchOnLoadInvoice() {
    yield takeLatest(GET_INVOICE_DATA, onLoadInvoiceData)
}

export default function* invoiceListSaga() {
    yield all([fork(watchOnLoadInvoiceList), fork(watchOnLoadInvoice)])
}
