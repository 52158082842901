import { AdminState } from '../../types/stateTypes'
import {
    SET_USER_COMPANIES_SUCCESS,
    UserCompaniesAction,
} from '../actionTypes/adminSetUserCompaniesActionTypes'
import {
    SET_USER_ENABLED_SUCCESS,
    UserEnabledAction,
} from '../actionTypes/adminSetUserEnabledActionTypes'
import {
    SET_USER_GROUP_SUCCESS,
    UserGroupAction,
} from '../actionTypes/adminSetUserGroupActionTypes'
import {
    GET_USER_LIST_DATA_SUCCESS,
    UserListDataAction,
} from '../actionTypes/adminUserListActionTypes'

const initialState: AdminState = { users: [], groups: [] }

export default function customerReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: AdminState = initialState,
    action:
        | UserListDataAction
        | UserGroupAction
        | UserEnabledAction
        | UserCompaniesAction
): AdminState {
    switch (action.type) {
        case GET_USER_LIST_DATA_SUCCESS: {
            return {
                ...state,
                groups: action.usersInGroups.map((group) => group.groupName),
                users: action.usersInGroups.flatMap((groups) => groups.users),
            }
        }
        case SET_USER_GROUP_SUCCESS:
        case SET_USER_ENABLED_SUCCESS:
        case SET_USER_COMPANIES_SUCCESS: {
            return {
                ...state,
                users: [
                    ...state.users.filter(
                        (user) => user.username !== action.user.username
                    ),
                    action.user,
                ],
            }
        }
        default:
            return state
    }
}
