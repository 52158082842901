import { put, call, takeLatest, all, fork, select } from 'redux-saga/effects'

import { fetchUserData } from '../services/userService'
import { GET_USER_DATA } from '../actionTypes/userActionTypes'
import {
    getUserdataFailure,
    getUserdataRequest,
    getUserdataSuccess,
} from '../actionCreators/userActionCreators'
import { UserDto } from '../../types/dtoTypes'
import { UserState } from '../../types/stateTypes'
import { shouldDataBeUpdated, DataType } from '../../utils/date'
import { AppState } from '../reducers/rootReducer'

export const getUserState = (state: AppState): UserState => state.user

function* onLoadUserData() {
    try {
        const userState: UserState = yield select(getUserState)

        // Don't fetch anything if it was already fetched 5mins ago
        if (!shouldDataBeUpdated(DataType.UserData, userState.updated)) {
            return
        }

        yield put(getUserdataRequest())
        const userData: UserDto = yield call(fetchUserData)
        const updated = new Date()
        yield put(getUserdataSuccess({ ...userData, updated }))

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // eslint-disable-next-line no-console
        yield put(getUserdataFailure(error.response))
    }
}

function* watchOnLoadUserdata() {
    yield takeLatest(GET_USER_DATA, onLoadUserData)
}

export default function* userdataSaga() {
    yield all([fork(watchOnLoadUserdata)])
}
