import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { getUserData } from '../../saga/actionCreators/userActionCreators'
import {
    GET_USER_DATA,
    UserdataAction,
} from '../../saga/actionTypes/userActionTypes'

import { AppState } from '../../saga/reducers/rootReducer'
import { UserRole } from '../../types/dtoTypes'
import UserDetailsGuard from './UserDetailsGuard'

const mapStateToProps = (state: AppState) => ({
    isLoading: Boolean(state.isLoading[GET_USER_DATA]),
    isFetchingUserData: Boolean(state.isLoading[GET_USER_DATA]),
    error: state.error[GET_USER_DATA],
    isUserDataFetched: Boolean(
        state.user.updated &&
            state.user.role &&
            state.user.role >= UserRole.USER
    ),
})

const mapDispatchToProps = (dispatch: Dispatch<UserdataAction>) => ({
    fetchUserData: () => {
        dispatch(getUserData())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsGuard)
