import { UserDataDto } from '../../types/dtoTypes'
import {
    SetUserCompaniesAction,
    SetUserCompaniesFailureAction,
    SetUserCompaniesRequestAction,
    SetUserCompaniesSuccessAction,
    SET_USER_COMPANIES,
    SET_USER_COMPANIES_FAILURE,
    SET_USER_COMPANIES_REQUEST,
    SET_USER_COMPANIES_SUCCESS,
} from '../actionTypes/adminSetUserCompaniesActionTypes'

export function SetUserCompanies(
    username: string,
    companies: number[]
): SetUserCompaniesAction {
    return {
        type: SET_USER_COMPANIES,
        username,
        companies,
    }
}

export function SetUserCompaniesRequest(
    username: string,
    companies: number[]
): SetUserCompaniesRequestAction {
    return {
        type: SET_USER_COMPANIES_REQUEST,
        username,
        companies,
    }
}

export function SetUserCompaniesSuccess(
    user: UserDataDto
): SetUserCompaniesSuccessAction {
    return {
        type: SET_USER_COMPANIES_SUCCESS,
        user,
    }
}

export function SetUserCompaniesFailure(
    error: Error | string
): SetUserCompaniesFailureAction {
    return {
        type: SET_USER_COMPANIES_FAILURE,
        error,
    }
}
