import { combineReducers } from 'redux'
import userReducer from './userReducer'
import customerReducer from './customerReducer'
import isLoadingReducer from './isLoadingReducer'
import errorReducer from './errorReducer'
import coveringLetterReducer from './coveringLetterReducer'
import invoiceReducer from './invoiceReducer'
import calculationReducer from './calculationReducer'
import expressOrderReducer from './expressOrderReducer'
import adminReducer from './adminReducer'

const rootReducer = combineReducers({
    user: userReducer,
    customer: customerReducer,
    isLoading: isLoadingReducer,
    error: errorReducer,
    coveringLetter: coveringLetterReducer,
    invoice: invoiceReducer,
    calculation: calculationReducer,
    expressOrder: expressOrderReducer,
    admin: adminReducer,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
