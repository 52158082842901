import { UserDataDto } from '../../types/dtoTypes'

export const SET_USER_GROUP = 'adminUserSetGroupActionTypes/SET_USER_GROUP'
export interface SetUserGroupAction {
    type: typeof SET_USER_GROUP
    username: string
    group: string
}

export const SET_USER_GROUP_REQUEST =
    'adminUserSetGroupActionTypes/SET_USER_GROUP_REQUEST'
export interface SetUserGroupRequestAction {
    type: typeof SET_USER_GROUP_REQUEST
    username: string
    group: string
}

export const SET_USER_GROUP_SUCCESS =
    'adminUserSetGroupActionTypes/SET_USER_GROUP_SUCCESS'
export interface SetUserGroupSuccessAction {
    type: typeof SET_USER_GROUP_SUCCESS
    user: UserDataDto
}

export const SET_USER_GROUP_FAILURE =
    'adminUserSetGroupActionTypes/SET_USER_GROUP_FAILURE'
export interface SetUserGroupFailureAction {
    type: typeof SET_USER_GROUP_FAILURE
    error: Error | string
}

export type UserGroupAction =
    | SetUserGroupAction
    | SetUserGroupRequestAction
    | SetUserGroupSuccessAction
    | SetUserGroupFailureAction
