/* eslint-disable import/prefer-default-export */

import API from '@aws-amplify/api-rest'
import { CustomerV1 } from '../../futursoft/generated/warehouse'

export async function fetchCustomerData(
    customerId: number
): Promise<CustomerV1> {
    return API.get('getDataFromFutursoft', `/api/customer/${customerId}`, {})
}
