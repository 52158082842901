import {
    ExpressOrderWithTtl,
    PaginatedRequest,
    PaginatedRequestWithTtl,
} from '../../types/stateTypes'

export const GET_EXPRESS_ORDER_LIST_DATA =
    'expressOrderActionTypes/GET_EXPRESS_ORDER_LIST_DATA'
export interface GetExpressOrderListDataAction {
    type: typeof GET_EXPRESS_ORDER_LIST_DATA
    request: PaginatedRequest
}

export const GET_EXPRESS_ORDER_LIST_DATA_REQUEST =
    'expressOrderActionTypes/GET_EXPRESS_ORDER_LIST_DATA_REQUEST'
export interface GetExpressOrderListDataRequestAction {
    type: typeof GET_EXPRESS_ORDER_LIST_DATA_REQUEST
    request: PaginatedRequest
}

export const GET_EXPRESS_ORDER_LIST_DATA_SUCCESS =
    'expressOrderActionTypes/GET_EXPRESS_ORDER_LIST_DATA_SUCCESS'
export interface GetExpressOrderListDataSuccessAction {
    type: typeof GET_EXPRESS_ORDER_LIST_DATA_SUCCESS
    expressOrders: ExpressOrderWithTtl[]
    request: PaginatedRequestWithTtl
}

export const GET_EXPRESS_ORDER_LIST_DATA_FAILURE =
    'expressOrderActionTypes/GET_EXPRESS_ORDER_LIST_DATA_FAILURE'
export interface GetExpressOrderListDataFailureAction {
    type: typeof GET_EXPRESS_ORDER_LIST_DATA_FAILURE
    error: Error | string
}

export type ExpressOrderListDataAction =
    | GetExpressOrderListDataAction
    | GetExpressOrderListDataRequestAction
    | GetExpressOrderListDataSuccessAction
    | GetExpressOrderListDataFailureAction
