import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App'
import LoadingIndicator from './components/LoadingIndicator'

const CustomerSelectionPage = React.lazy(
    () => import('./pages/CustomerSelection')
)
const CustomerFrontPage = React.lazy(() => import('./pages/CustomerFrontPage'))
const CoveringLetterListPage = React.lazy(
    () => import('./pages/CoveringLetterListPage')
)
const CalculationListPage = React.lazy(
    () => import('./pages/CalculationListPage')
)
const InvoiceListPage = React.lazy(() => import('./pages/InvoiceListPage'))
const ExpressOrderListPage = React.lazy(
    () => import('./pages/ExpressOrderListPage')
)
const InvoicePage = React.lazy(() => import('./pages/InvoicePage'))
const CoveringLetterPage = React.lazy(
    () => import('./pages/CoveringLetterPage')
)
const ExpressOrderPage = React.lazy(() => import('./pages/ExpressOrderPage'))
const CalculationPage = React.lazy(() => import('./pages/CalculationPage'))
const TestPage = React.lazy(() => import('./pages/TestPage'))
const AdminUserListPage = React.lazy(() => import('./pages/Admin/UserListPage'))

const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route
                    index
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <CustomerSelectionPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/test"
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <TestPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/admin/users"
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <AdminUserListPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/customer/:customerId"
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <CustomerFrontPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/customer/:customerId/calculations"
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <CalculationListPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/customer/:customerId/calculation/:calculationNumber"
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <CalculationPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/customer/:customerId/expressorders"
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <ExpressOrderListPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/customer/:customerId/expressorder/:expressOrderNumber"
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <ExpressOrderPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/customer/:customerId/invoice/:invoiceNumber"
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <InvoicePage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/customer/:customerId/coveringletters"
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <CoveringLetterListPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/customer/:customerId/coveringletter/:coveringLetterNumber"
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <CoveringLetterPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/customer/:customerId/invoices"
                    element={
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <InvoiceListPage />
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    </BrowserRouter>
)

export default Router
