import { InvoiceWithTtl } from '../../types/stateTypes'

export const GET_INVOICE_DATA = 'invoiceActionTypes/GET_INVOICE_DATA'
export interface GetInvoiceDataAction {
    type: typeof GET_INVOICE_DATA
    invoiceNumber: number
}

export const GET_INVOICE_DATA_REQUEST =
    'invoiceActionTypes/GET_INVOICE_DATA_REQUEST'
export interface GetInvoiceDataRequestAction {
    type: typeof GET_INVOICE_DATA_REQUEST
    invoiceNumber: number
}

export const GET_INVOICE_DATA_SUCCESS =
    'invoiceActionTypes/GET_INVOICE_DATA_SUCCESS'
export interface GetInvoiceDataSuccessAction {
    type: typeof GET_INVOICE_DATA_SUCCESS
    invoice: InvoiceWithTtl
    invoiceNumber: number
}

export const GET_INVOICE_DATA_FAILURE =
    'invoiceActionTypes/GET_INVOICE_DATA_FAILURE'
export interface GetInvoiceDataFailureAction {
    type: typeof GET_INVOICE_DATA_FAILURE
    error: Error | string
}

export type InvoiceDataAction =
    | GetInvoiceDataAction
    | GetInvoiceDataRequestAction
    | GetInvoiceDataSuccessAction
    | GetInvoiceDataFailureAction
