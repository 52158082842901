import { UserDataDto } from '../../types/dtoTypes'
import {
    SetUserGroupAction,
    SetUserGroupFailureAction,
    SetUserGroupRequestAction,
    SetUserGroupSuccessAction,
    SET_USER_GROUP,
    SET_USER_GROUP_FAILURE,
    SET_USER_GROUP_REQUEST,
    SET_USER_GROUP_SUCCESS,
} from '../actionTypes/adminSetUserGroupActionTypes'

export function SetUserGroup(
    username: string,
    group: string
): SetUserGroupAction {
    return {
        type: SET_USER_GROUP,
        username,
        group,
    }
}

export function SetUserGroupRequest(
    username: string,
    group: string
): SetUserGroupRequestAction {
    return {
        type: SET_USER_GROUP_REQUEST,
        username,
        group,
    }
}

export function SetUserGroupSuccess(
    user: UserDataDto
): SetUserGroupSuccessAction {
    return {
        type: SET_USER_GROUP_SUCCESS,
        user,
    }
}

export function SetUserGroupFailure(
    error: Error | string
): SetUserGroupFailureAction {
    return {
        type: SET_USER_GROUP_FAILURE,
        error,
    }
}
