/* eslint-disable import/prefer-default-export */

import API from '@aws-amplify/api-rest'
import { Invoice } from '../../futursoft/generated/sales'
import { InvoiceListDto } from '../../types/dtoTypes'
import { PaginatedRequest } from '../../types/stateTypes'
import { dateToIsoDate } from '../../utils/date'
import { removeEmpty } from '../../utils/request'

export async function fetchInvoiceList({
    customerNr,
    pageNumber,
    pageSize,
    start,
    end,
}: PaginatedRequest): Promise<InvoiceListDto> {
    return API.get('getDataFromFutursoft', '/api/invoices', {
        queryStringParameters: removeEmpty({
            customerNr,
            pageNumber,
            pageSize,
            start: start ? dateToIsoDate(start) : null,
            end: end ? dateToIsoDate(end) : null,
        }),
    })
}

export async function fetchInvoice(invoiceNumber: number): Promise<Invoice> {
    return API.get('getDataFromFutursoft', `/api/invoice/${invoiceNumber}`, {})
}
