import {
    InvoiceWithTtl,
    PaginatedRequest,
    PaginatedRequestWithTtl,
} from '../../types/stateTypes'
import {
    GetInvoiceListDataAction,
    GetInvoiceListDataFailureAction,
    GetInvoiceListDataRequestAction,
    GetInvoiceListDataSuccessAction,
    GET_INVOICE_LIST_DATA,
    GET_INVOICE_LIST_DATA_FAILURE,
    GET_INVOICE_LIST_DATA_REQUEST,
    GET_INVOICE_LIST_DATA_SUCCESS,
} from '../actionTypes/invoiceListActionTypes'

export function getInvoiceListData(
    request: PaginatedRequest
): GetInvoiceListDataAction {
    return {
        type: GET_INVOICE_LIST_DATA,
        request,
    }
}

export function getInvoiceListDataRequest(
    request: PaginatedRequest
): GetInvoiceListDataRequestAction {
    return {
        type: GET_INVOICE_LIST_DATA_REQUEST,
        request,
    }
}

export function getInvoiceListDataSuccess(
    invoices: InvoiceWithTtl[],
    request: PaginatedRequestWithTtl
): GetInvoiceListDataSuccessAction {
    return {
        type: GET_INVOICE_LIST_DATA_SUCCESS,
        invoices,
        request,
    }
}

export function getInvoiceListDataFailure(
    error: Error | string
): GetInvoiceListDataFailureAction {
    return {
        type: GET_INVOICE_LIST_DATA_FAILURE,
        error,
    }
}
