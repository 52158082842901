import React from 'react'
import type { LinkProps } from 'react-router-dom'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import { Anchor, Text } from '@mantine/core'

const NavigationLink = ({ children, to, ...props }: LinkProps) => {
    const resolved = useResolvedPath(to)
    const match = useMatch({ path: resolved.pathname, end: true })
    if (match) {
        return <Text>{children}</Text>
    }

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Anchor component={Link} to={to} {...props}>
            {children}
        </Anchor>
    )
}

export default NavigationLink
