import { UserDataDto } from '../../types/dtoTypes'
import {
    SetUserEnabledAction,
    SetUserEnabledFailureAction,
    SetUserEnabledRequestAction,
    SetUserEnabledSuccessAction,
    SET_USER_ENABLED,
    SET_USER_ENABLED_FAILURE,
    SET_USER_ENABLED_REQUEST,
    SET_USER_ENABLED_SUCCESS,
} from '../actionTypes/adminSetUserEnabledActionTypes'

export function SetUserEnabled(
    username: string,
    enabled: boolean
): SetUserEnabledAction {
    return {
        type: SET_USER_ENABLED,
        username,
        enabled,
    }
}

export function SetUserEnabledRequest(
    username: string,
    enabled: boolean
): SetUserEnabledRequestAction {
    return {
        type: SET_USER_ENABLED_REQUEST,
        username,
        enabled,
    }
}

export function SetUserEnabledSuccess(
    user: UserDataDto
): SetUserEnabledSuccessAction {
    return {
        type: SET_USER_ENABLED_SUCCESS,
        user,
    }
}

export function SetUserEnabledFailure(
    error: Error | string
): SetUserEnabledFailureAction {
    return {
        type: SET_USER_ENABLED_FAILURE,
        error,
    }
}
