/* eslint-disable import/prefer-default-export */

import API from '@aws-amplify/api-rest'
import { CoveringLetter } from '../../futursoft/generated/sales'
import { CoveringLetterListDto } from '../../types/dtoTypes'
import { PaginatedRequest } from '../../types/stateTypes'
import { dateToIsoDate } from '../../utils/date'
import { removeEmpty } from '../../utils/request'

export async function fetchCoveringLetterList({
    customerNr,
    pageNumber,
    pageSize,
    start,
    end,
}: PaginatedRequest): Promise<CoveringLetterListDto> {
    return API.get('getDataFromFutursoft', '/api/covering-letters', {
        queryStringParameters: removeEmpty({
            customerNr,
            pageNumber,
            pageSize,
            start: start ? dateToIsoDate(start) : null,
            end: end ? dateToIsoDate(end) : null,
        }),
    })
}

export async function fetchCoveringLetter(
    coveringLetterId: number
): Promise<CoveringLetter> {
    return API.get(
        'getDataFromFutursoft',
        `/api/covering-letter/${coveringLetterId}`,
        {}
    )
}
