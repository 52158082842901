/* eslint-disable import/prefer-default-export */

import API from '@aws-amplify/api-rest'
import { ExpressOrderListDto } from '../../types/dtoTypes'
import { PaginatedRequest } from '../../types/stateTypes'

export async function fetchExpressOrderList({
    customerNr,
    pageNumber,
    pageSize,
}: PaginatedRequest): Promise<ExpressOrderListDto> {
    return API.get('getDataFromFutursoft', '/api/express-orders', {
        queryStringParameters: {
            customerNr,
            pageNumber,
            pageSize,
            open: true,
        },
    })
}
