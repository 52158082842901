import React, { useEffect } from 'react'
import ErrorOrLoadingIndicator from '../ErrorOrLoadingIndicator'

export type UserDetailsGuardProps = {
    children: React.ReactNode
    fetchUserData: () => void
    isFetchingUserData: boolean
    isUserDataFetched: boolean
    error: Error | string | null
}

const UserDetailsGuard = ({
    children,
    fetchUserData,
    isFetchingUserData,
    isUserDataFetched,
    error,
}: UserDetailsGuardProps) => {
    useEffect(
        () => {
            if (!isFetchingUserData && !isUserDataFetched) {
                fetchUserData()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return (
        <ErrorOrLoadingIndicator error={error} isLoading={isFetchingUserData}>
            {children}
        </ErrorOrLoadingIndicator>
    )
}

export default UserDetailsGuard
