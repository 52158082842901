import { InvoiceWithTtl } from '../../types/stateTypes'
import {
    GetInvoiceDataAction,
    GetInvoiceDataFailureAction,
    GetInvoiceDataRequestAction,
    GetInvoiceDataSuccessAction,
    GET_INVOICE_DATA,
    GET_INVOICE_DATA_FAILURE,
    GET_INVOICE_DATA_REQUEST,
    GET_INVOICE_DATA_SUCCESS,
} from '../actionTypes/invoiceActionTypes'

export function getInvoiceData(invoiceNumber: number): GetInvoiceDataAction {
    return {
        type: GET_INVOICE_DATA,
        invoiceNumber,
    }
}

export function getInvoiceDataRequest(
    invoiceNumber: number
): GetInvoiceDataRequestAction {
    return {
        type: GET_INVOICE_DATA_REQUEST,
        invoiceNumber,
    }
}

export function getInvoiceDataSuccess(
    invoice: InvoiceWithTtl,
    invoiceNumber: number
): GetInvoiceDataSuccessAction {
    return {
        type: GET_INVOICE_DATA_SUCCESS,
        invoice,
        invoiceNumber,
    }
}

export function getInvoiceDataFailure(
    error: Error | string
): GetInvoiceDataFailureAction {
    return {
        type: GET_INVOICE_DATA_FAILURE,
        error,
    }
}
