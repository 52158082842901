import React from 'react'
import { Title, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export type ErrorMessageProps = {
    error: Error | string
}

const errorToHumanReadable = (error: Error | string): string => {
    if (typeof error === 'string') {
        return error
    }

    return (error as Error).message
}

const ErrorMessage = ({ error }: ErrorMessageProps) => {
    const { t } = useTranslation()
    const errorMessage = errorToHumanReadable(error)
    // eslint-disable-next-line no-console
    console.log(errorMessage)

    return (
        <>
            <Title order={1}>{t('errorPage.genericErrorText')}</Title>
            <Text>{t('errorPage.genericPleaseTryAgainLater')}</Text>
        </>
    )
}

export default ErrorMessage
