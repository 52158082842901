import format from 'date-fns/format'

const dateToString = (value: Date): string =>
    format(new Date(value), 'dd.MM.yyyy')

export const dateTimeToString = (value?: Date): string =>
    value ? format(new Date(value), 'dd.MM.yyyy HH:mm:ss') : '-'

export const dateToIsoDate = (value: Date) =>
    value.toISOString().substring(0, 10)

export enum DataType {
    UserData,
    CustomerData,
    InvoiceData,
    CoveringLetterData,
    ExpressOrderData,
    CalculationData,
}

const getDataUpdateIntervalMinutes = (type: DataType): number => {
    const defaultInterval = 10
    const week = 10080
    // const month = 2629800
    const hour = 3600

    switch (type) {
        case DataType.UserData:
            // User data stays valid for a whole week
            return hour
        case DataType.CustomerData:
            // Customer data also valid
            return week
        case DataType.ExpressOrderData:
        case DataType.CalculationData:
        case DataType.InvoiceData:
        case DataType.CoveringLetterData:
            return defaultInterval
        default:
            return defaultInterval
    }
}

export const isSameDate = (first: Date, second: Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDay() === second.getDay()

export const shouldDataBeUpdated = (type: DataType, lastUpdated?: Date) =>
    !lastUpdated ||
    new Date().getTime() - new Date(lastUpdated).getTime() >=
        1000 * 60 * getDataUpdateIntervalMinutes(type)

export default dateToString

export const getDefaultListStartDate = () => new Date('2000-01-01T00:00:00Z')
export const getDefaultListEndDate = () => new Date()
