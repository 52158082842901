/* eslint-disable import/prefer-default-export */

import API from '@aws-amplify/api-rest'
import { CoveringLetterListDto } from '../../types/dtoTypes'
import { PaginatedRequest } from '../../types/stateTypes'

export async function fetchCalculationList({
    customerNr,
    pageNumber,
    pageSize,
}: PaginatedRequest): Promise<CoveringLetterListDto> {
    return API.get('getDataFromFutursoft', '/api/calculations', {
        queryStringParameters: {
            customerNr,
            pageNumber,
            pageSize,
            open: true,
            rows: true,
        },
    })
}
