import { CustomerWithTtl } from '../../types/stateTypes'
import {
    GetCustomerDataAction,
    GetCustomerDataFailureAction,
    GetCustomerDataRequestAction,
    GetCustomerDataSuccessAction,
    GET_CUSTOMER_DATA,
    GET_CUSTOMER_DATA_FAILURE,
    GET_CUSTOMER_DATA_REQUEST,
    GET_CUSTOMER_DATA_SUCCESS,
} from '../actionTypes/customerActionTypes'

export function getCustomerData(customerId: number): GetCustomerDataAction {
    return {
        type: GET_CUSTOMER_DATA,
        customerId,
    }
}

export function getCustomerDataRequest(
    customerId: number
): GetCustomerDataRequestAction {
    return {
        type: GET_CUSTOMER_DATA_REQUEST,
        customerId,
    }
}

export function getCustomerDataSuccess(
    customer: CustomerWithTtl
): GetCustomerDataSuccessAction {
    return {
        type: GET_CUSTOMER_DATA_SUCCESS,
        customer,
    }
}

export function getCustomerDataFailure(
    error: Error | string
): GetCustomerDataFailureAction {
    return {
        type: GET_CUSTOMER_DATA_FAILURE,
        error,
    }
}
