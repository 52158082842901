import { UsersInGroupDto } from '../../types/dtoTypes'

export const GET_USER_LIST_DATA = 'userListActionTypes/GET_USER_LIST_DATA'
export interface GetUserListDataAction {
    type: typeof GET_USER_LIST_DATA
}

export const GET_USER_LIST_DATA_REQUEST =
    'userListActionTypes/GET_USER_LIST_DATA_REQUEST'
export interface GetUserListDataRequestAction {
    type: typeof GET_USER_LIST_DATA_REQUEST
}

export const GET_USER_LIST_DATA_SUCCESS =
    'userListActionTypes/GET_USER_LIST_DATA_SUCCESS'
export interface GetUserListDataSuccessAction {
    type: typeof GET_USER_LIST_DATA_SUCCESS
    usersInGroups: UsersInGroupDto[]
}

export const GET_USER_LIST_DATA_FAILURE =
    'userListActionTypes/GET_USER_LIST_DATA_FAILURE'
export interface GetUserListDataFailureAction {
    type: typeof GET_USER_LIST_DATA_FAILURE
    error: Error | string
}

export type UserListDataAction =
    | GetUserListDataAction
    | GetUserListDataRequestAction
    | GetUserListDataSuccessAction
    | GetUserListDataFailureAction
