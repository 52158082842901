import { put, call, takeLatest, all, fork, select } from 'redux-saga/effects'

import { CoveringLetterListDto } from '../../types/dtoTypes'
import {
    CoveringLetterWithTtl,
    PaginatedRequestWithTtl,
} from '../../types/stateTypes'
import { shouldDataBeUpdated, DataType } from '../../utils/date'
import { AppState } from '../reducers/rootReducer'
import {
    GetCoveringLetterListDataAction,
    GET_COVERING_LETTER_LIST_DATA,
} from '../actionTypes/coveringLetterListActionTypes'
import {
    getCoveringLetterListDataFailure,
    getCoveringLetterListDataRequest,
    getCoveringLetterListDataSuccess,
} from '../actionCreators/coveringLetterListActionCreators'
import { fetchCoveringLetterList } from '../services/coveringLetterService'
import { findSimilarRequest } from '../../utils/request'

export const getCoveringLetterRequests = (
    state: AppState
): PaginatedRequestWithTtl[] => state.coveringLetter.requests || []

function* onLoadCoveringLetterListData({
    request,
}: GetCoveringLetterListDataAction) {
    try {
        const oldRequests: PaginatedRequestWithTtl[] = yield select(
            getCoveringLetterRequests
        )
        const lastSimilarRequest = findSimilarRequest(request, oldRequests)

        if (
            !shouldDataBeUpdated(
                DataType.CoveringLetterData,
                lastSimilarRequest?.updated
            )
        ) {
            return
        }

        yield put(getCoveringLetterListDataRequest(request))

        const coveringLetterList: CoveringLetterListDto = yield call(
            fetchCoveringLetterList,
            request
        )

        const now = new Date()
        const coveringLettersWithTtl: CoveringLetterWithTtl[] =
            coveringLetterList.data.map((coveringLetter) => ({
                ...coveringLetter,
                updated: now,
            }))
        yield put(
            getCoveringLetterListDataSuccess(coveringLettersWithTtl, {
                ...request,
                updated: now,
            })
        )

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error)
        yield put(getCoveringLetterListDataFailure(error.response))
    }
}

function* watchOnLoadCoveringLetterList() {
    yield takeLatest(
        GET_COVERING_LETTER_LIST_DATA,
        onLoadCoveringLetterListData
    )
}

export default function* coveringLetterListSaga() {
    yield all([fork(watchOnLoadCoveringLetterList)])
}
