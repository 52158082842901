import { CustomerState, CustomerWithTtl } from '../../types/stateTypes'
import {
    GET_CUSTOMER_DATA_SUCCESS,
    CustomerDataAction,
} from '../actionTypes/customerActionTypes'

const initialState: CustomerState = { data: [] }

export default function customerReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: CustomerState = initialState,
    action: CustomerDataAction
): CustomerState {
    switch (action.type) {
        case GET_CUSTOMER_DATA_SUCCESS: {
            const customerId = action.customer.customerNr

            const oldUnrelatedCustomers: CustomerWithTtl[] = state.data.filter(
                (customer) => customer.customerNr !== customerId
            )

            return {
                ...state,
                data: [...oldUnrelatedCustomers, action.customer],
            }
        }
        default:
            return state
    }
}
