import React, { useEffect } from 'react'
import { Select } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

const supportedLanguages = ['fi', 'sv', 'en']

const setLanguage = async (language: string) => {
    const result = await i18n.changeLanguage(language)
    return Promise.resolve(result)
}

const ChooseLanguageDropdown = () => {
    const { t } = useTranslation()
    const defaultLanguage = supportedLanguages[0]
    const currentLanguageIsSupported = supportedLanguages.includes(
        i18n.language
    )
    useEffect(() => {
        if (!currentLanguageIsSupported) {
            setLanguage(defaultLanguage)
        }
    }, [currentLanguageIsSupported, defaultLanguage])

    const selectedLanguage: string = currentLanguageIsSupported
        ? i18n.language
        : defaultLanguage

    return (
        <Select
            size="xs"
            value={selectedLanguage}
            data={supportedLanguages.map((language) => ({
                value: language,
                label: t(`language.${language}`),
            }))}
            onChange={setLanguage}
        />
    )
}

export default ChooseLanguageDropdown
