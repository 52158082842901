import { UserDataDto } from '../../types/dtoTypes'

export const SET_USER_COMPANIES =
    'adminUserSetCompaniesActionTypes/SET_USER_COMPANIES'
export interface SetUserCompaniesAction {
    type: typeof SET_USER_COMPANIES
    username: string
    companies: number[]
}

export const SET_USER_COMPANIES_REQUEST =
    'adminUserSetCompaniesActionTypes/SET_USER_COMPANIES_REQUEST'
export interface SetUserCompaniesRequestAction {
    type: typeof SET_USER_COMPANIES_REQUEST
    username: string
    companies: number[]
}

export const SET_USER_COMPANIES_SUCCESS =
    'adminUserSetCompaniesActionTypes/SET_USER_COMPANIES_SUCCESS'
export interface SetUserCompaniesSuccessAction {
    type: typeof SET_USER_COMPANIES_SUCCESS
    user: UserDataDto
}

export const SET_USER_COMPANIES_FAILURE =
    'adminUserSetCompaniesActionTypes/SET_USER_COMPANIES_FAILURE'
export interface SetUserCompaniesFailureAction {
    type: typeof SET_USER_COMPANIES_FAILURE
    error: Error | string
}

export type UserCompaniesAction =
    | SetUserCompaniesAction
    | SetUserCompaniesRequestAction
    | SetUserCompaniesSuccessAction
    | SetUserCompaniesFailureAction
