import { connect } from 'react-redux'
import { AppState } from '../../saga/reducers/rootReducer'
import Navigation from './Navigation'

const mapStateToProps = (state: AppState) => ({
    customerIds: state.user.companies,
    customers: state.customer.data,
})

export default connect(mapStateToProps)(Navigation)
