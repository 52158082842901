import React from 'react'
import { Loader, Center } from '@mantine/core'

const LoadingIndicator = () => (
    <Center>
        <Loader size="lg" variant="dots" />
    </Center>
)

export default LoadingIndicator
