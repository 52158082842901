import {
    InvoiceWithTtl,
    PaginatedRequest,
    PaginatedRequestWithTtl,
} from '../../types/stateTypes'

export const GET_INVOICE_LIST_DATA = 'invoiceActionTypes/GET_INVOICE_LIST_DATA'
export interface GetInvoiceListDataAction {
    type: typeof GET_INVOICE_LIST_DATA
    request: PaginatedRequest
}

export const GET_INVOICE_LIST_DATA_REQUEST =
    'invoiceActionTypes/GET_INVOICE_LIST_DATA_REQUEST'
export interface GetInvoiceListDataRequestAction {
    type: typeof GET_INVOICE_LIST_DATA_REQUEST
    request: PaginatedRequest
}

export const GET_INVOICE_LIST_DATA_SUCCESS =
    'invoiceActionTypes/GET_INVOICE_LIST_DATA_SUCCESS'
export interface GetInvoiceListDataSuccessAction {
    type: typeof GET_INVOICE_LIST_DATA_SUCCESS
    invoices: InvoiceWithTtl[]
    request: PaginatedRequestWithTtl
}

export const GET_INVOICE_LIST_DATA_FAILURE =
    'invoiceActionTypes/GET_INVOICE_LIST_DATA_FAILURE'
export interface GetInvoiceListDataFailureAction {
    type: typeof GET_INVOICE_LIST_DATA_FAILURE
    error: Error | string
}

export type InvoiceListDataAction =
    | GetInvoiceListDataAction
    | GetInvoiceListDataRequestAction
    | GetInvoiceListDataSuccessAction
    | GetInvoiceListDataFailureAction
