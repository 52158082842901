import React from 'react'
import ReactDOM from 'react-dom'
import { Amplify } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { Provider } from 'react-redux'
import { Authenticator } from '@aws-amplify/ui-react'
import { MantineProvider } from '@mantine/core'
import config from './aws-exports'
import Router from './router'
import configureStore from './saga/store/configureStore'
import LoadingIndicator from './components/LoadingIndicator'
import UserDetailsGuard from './components/UserDetailsGuard'
import './i18n'
import '@aws-amplify/ui-react/styles.css'

const store = configureStore()
const customHeader = async () => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
})
Amplify.configure({
    ...config,
    API: {
        endpoints: [
            {
                name: 'getUserApi',
                endpoint:
                    'https://5ltnq2stl2.execute-api.eu-north-1.amazonaws.com/dev',
                custom_header: customHeader,
            },
            {
                name: 'getDataFromFutursoft',
                endpoint:
                    'https://rtq607v1wl.execute-api.eu-north-1.amazonaws.com/dev',
                custom_header: customHeader,
            },
            {
                name: 'AdminQueries',
                endpoint:
                    'https://zo67swmn09.execute-api.eu-north-1.amazonaws.com/dev',
                custom_header: customHeader,
            },
        ],
    },
})

ReactDOM.render(
    <React.StrictMode>
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
                colorScheme: 'light',
            }}
        >
            <Authenticator>
                {({ /* signOut, */ user }) => {
                    if (!user) {
                        return <LoadingIndicator />
                    }

                    return (
                        <Provider store={store}>
                            <UserDetailsGuard>
                                <Router />
                            </UserDetailsGuard>
                        </Provider>
                    )
                }}
            </Authenticator>
        </MantineProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
