import { InvoiceState } from '../../types/stateTypes'
import { DataType, shouldDataBeUpdated } from '../../utils/date'
import {
    GET_INVOICE_LIST_DATA_SUCCESS,
    InvoiceListDataAction,
} from '../actionTypes/invoiceListActionTypes'
import {
    GET_INVOICE_DATA_SUCCESS,
    InvoiceDataAction,
} from '../actionTypes/invoiceActionTypes'

const initialState: InvoiceState = { data: [], requests: [] }

export default function customerReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: InvoiceState = initialState,
    action: InvoiceDataAction | InvoiceListDataAction
): InvoiceState {
    switch (action.type) {
        case GET_INVOICE_LIST_DATA_SUCCESS: {
            const invoiceIds = action.invoices.map(
                (invoice) => invoice.invoiceNr
            )

            const oldRequestsThatAreStillValid = state.requests
                ? state.requests.filter(
                      (request) =>
                          !shouldDataBeUpdated(
                              DataType.InvoiceData,
                              request.updated
                          )
                  )
                : []
            const oldUnrelatedInvoices = state.data.filter(
                (invoice) => !invoiceIds.includes(invoice.invoiceNr)
            )

            return {
                ...state,
                data: [...oldUnrelatedInvoices, ...action.invoices],
                requests: [...oldRequestsThatAreStillValid, action.request],
            }
        }
        case GET_INVOICE_DATA_SUCCESS: {
            const otherPreviouslyCachedInvoices = state.data.filter(
                (invoice) => invoice.invoiceNr !== action.invoice.invoiceNr
            )

            return {
                ...state,
                data: [...otherPreviouslyCachedInvoices, action.invoice],
            }
        }
        default:
            return state
    }
}
