import API from '@aws-amplify/api-rest'
import { UserDataDto, UsersInGroupDto } from '../../types/dtoTypes'

// See: https://docs.amplify.aws/cli/auth/admin/#example
export async function fetchUserListData(): Promise<UsersInGroupDto[]> {
    return API.get('getUserApi', '/admin/users', {})
}

export async function fetchUserData(username: string): Promise<UserDataDto> {
    return API.get('getUserApi', `/admin/user/get/${username}`, {})
}

export async function setUserEnabled(
    username: string,
    enable: boolean
): Promise<UserDataDto> {
    return API.post('getUserApi', `/admin/user/setEnabled`, {
        body: {
            username,
            enable,
        },
    })
}

export async function setUserGroup(
    username: string,
    group: string
): Promise<UserDataDto> {
    return API.post('getUserApi', `/admin/user/setGroup`, {
        body: {
            username,
            group,
        },
    })
}

export async function setUserCompanies(
    username: string,
    companyIds: number[]
): Promise<UserDataDto> {
    return API.post('getUserApi', `/admin/user/setCompanies`, {
        body: {
            username,
            companyIds,
        },
    })
}
