import { UserDataDto } from '../../types/dtoTypes'

export const SET_USER_ENABLED =
    'adminUserModificationActionTypes/SET_USER_ENABLED'
export interface SetUserEnabledAction {
    type: typeof SET_USER_ENABLED
    username: string
    enabled: boolean
}

export const SET_USER_ENABLED_REQUEST =
    'adminUserModificationActionTypes/SET_USER_ENABLED_REQUEST'
export interface SetUserEnabledRequestAction {
    type: typeof SET_USER_ENABLED_REQUEST
    username: string
    enabled: boolean
}

export const SET_USER_ENABLED_SUCCESS =
    'adminUserModificationActionTypes/SET_USER_ENABLED_SUCCESS'
export interface SetUserEnabledSuccessAction {
    type: typeof SET_USER_ENABLED_SUCCESS
    user: UserDataDto
}

export const SET_USER_ENABLED_FAILURE =
    'adminUserModificationActionTypes/SET_USER_ENABLED_FAILURE'
export interface SetUserEnabledFailureAction {
    type: typeof SET_USER_ENABLED_FAILURE
    error: Error | string
}

export type UserEnabledAction =
    | SetUserEnabledAction
    | SetUserEnabledRequestAction
    | SetUserEnabledSuccessAction
    | SetUserEnabledFailureAction
