import { UserData } from '../../types/stateTypes'

export const GET_USER_DATA = 'userActionTypes/GET_USER_DATA'
export interface GetUserdataAction {
    type: typeof GET_USER_DATA
}

export const GET_USER_DATA_REQUEST = 'userActionTypes/GET_USER_DATA_REQUEST'
export interface GetUserdataRequestAction {
    type: typeof GET_USER_DATA_REQUEST
}

export const GET_USER_DATA_SUCCESS = 'userActionTypes/GET_USER_DATA_SUCCESS'
export interface GetUserdataSuccessAction {
    type: typeof GET_USER_DATA_SUCCESS
    user: UserData
}

export const GET_USER_DATA_FAILURE = 'userActionTypes/GET_USER_DATA_FAILURE'
export interface GetUserdataFailureAction {
    type: typeof GET_USER_DATA_FAILURE
    error: Error | string
}

export type UserdataAction =
    | GetUserdataAction
    | GetUserdataRequestAction
    | GetUserdataSuccessAction
    | GetUserdataFailureAction
