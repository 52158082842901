import {
    CalculationWithTtl,
    PaginatedRequest,
    PaginatedRequestWithTtl,
} from '../../types/stateTypes'
import {
    GetCalculationListDataAction,
    GetCalculationListDataFailureAction,
    GetCalculationListDataRequestAction,
    GetCalculationListDataSuccessAction,
    GET_CALCULATION_LIST_DATA,
    GET_CALCULATION_LIST_DATA_FAILURE,
    GET_CALCULATION_LIST_DATA_REQUEST,
    GET_CALCULATION_LIST_DATA_SUCCESS,
} from '../actionTypes/calculationListActionTypes'

export function getCalculationListData(
    request: PaginatedRequest
): GetCalculationListDataAction {
    return {
        type: GET_CALCULATION_LIST_DATA,
        request,
    }
}

export function getCalculationListDataRequest(
    request: PaginatedRequest
): GetCalculationListDataRequestAction {
    return {
        type: GET_CALCULATION_LIST_DATA_REQUEST,
        request,
    }
}

export function getCalculationListDataSuccess(
    calculations: CalculationWithTtl[],
    request: PaginatedRequestWithTtl
): GetCalculationListDataSuccessAction {
    return {
        type: GET_CALCULATION_LIST_DATA_SUCCESS,
        calculations,
        request,
    }
}

export function getCalculationListDataFailure(
    error: Error | string
): GetCalculationListDataFailureAction {
    return {
        type: GET_CALCULATION_LIST_DATA_FAILURE,
        error,
    }
}
