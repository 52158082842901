import { UserData } from '../../types/stateTypes'
import {
    GetUserdataAction,
    GetUserdataFailureAction,
    GetUserdataRequestAction,
    GetUserdataSuccessAction,
    GET_USER_DATA,
    GET_USER_DATA_FAILURE,
    GET_USER_DATA_REQUEST,
    GET_USER_DATA_SUCCESS,
} from '../actionTypes/userActionTypes'

export function getUserData(): GetUserdataAction {
    return {
        type: GET_USER_DATA,
    }
}

export function getUserdataRequest(): GetUserdataRequestAction {
    return {
        type: GET_USER_DATA_REQUEST,
    }
}

export function getUserdataSuccess(user: UserData): GetUserdataSuccessAction {
    return {
        type: GET_USER_DATA_SUCCESS,
        user,
    }
}

export function getUserdataFailure(
    error: Error | string
): GetUserdataFailureAction {
    return {
        type: GET_USER_DATA_FAILURE,
        error,
    }
}
