import { put, call, takeLatest, all, fork, select } from 'redux-saga/effects'
import { ExpressOrderListDto } from '../../types/dtoTypes'
import {
    ExpressOrderWithTtl,
    PaginatedRequestWithTtl,
} from '../../types/stateTypes'
import { shouldDataBeUpdated, DataType } from '../../utils/date'
import { AppState } from '../reducers/rootReducer'
import { findSimilarRequest } from '../../utils/request'
import {
    GetExpressOrderListDataAction,
    GET_EXPRESS_ORDER_LIST_DATA,
} from '../actionTypes/expressOrderListActionTypes'
import {
    getExpressOrderListDataFailure,
    getExpressOrderListDataRequest,
    getExpressOrderListDataSuccess,
} from '../actionCreators/expressOrderListActionCreators'
import { fetchExpressOrderList } from '../services/expressOrderService'

export const getExpressOrderListRequests = (
    state: AppState
): PaginatedRequestWithTtl[] => state.expressOrder.requests || []

function* onLoadExpressOrderListData({
    request,
}: GetExpressOrderListDataAction) {
    try {
        const oldRequests: PaginatedRequestWithTtl[] = yield select(
            getExpressOrderListRequests
        )
        const lastSimilarRequest = findSimilarRequest(request, oldRequests)

        if (
            !shouldDataBeUpdated(
                DataType.ExpressOrderData,
                lastSimilarRequest?.updated
            )
        ) {
            return
        }

        yield put(getExpressOrderListDataRequest(request))

        const expressOrderList: ExpressOrderListDto = yield call(
            fetchExpressOrderList,
            request
        )

        const now = new Date()
        const expressOrdersWithTtl: ExpressOrderWithTtl[] =
            expressOrderList.data.map((expressOrder) => ({
                ...expressOrder,
                updated: now,
            }))
        yield put(
            getExpressOrderListDataSuccess(expressOrdersWithTtl, {
                ...request,
                updated: now,
            })
        )

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error)
        yield put(getExpressOrderListDataFailure(error.response))
    }
}

function* watchOnLoadExpressOrderList() {
    yield takeLatest(GET_EXPRESS_ORDER_LIST_DATA, onLoadExpressOrderListData)
}

export default function* expressOrderListSaga() {
    yield all([fork(watchOnLoadExpressOrderList)])
}
