import { CalculationState } from '../../types/stateTypes'
import { DataType, shouldDataBeUpdated } from '../../utils/date'
import {
    CalculationListDataAction,
    GET_CALCULATION_LIST_DATA_SUCCESS,
} from '../actionTypes/calculationListActionTypes'

const initialState: CalculationState = { data: [], requests: [] }

export default function customerReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: CalculationState = initialState,
    action: CalculationListDataAction
): CalculationState {
    switch (action.type) {
        case GET_CALCULATION_LIST_DATA_SUCCESS: {
            const calculationIds = action.calculations.map(
                (calculation) => calculation.calculationNr
            )

            const oldRequestsThatAreStillValid = state.requests
                ? state.requests.filter(
                      (request) =>
                          !shouldDataBeUpdated(
                              DataType.CoveringLetterData,
                              request.updated
                          )
                  )
                : []
            const oldUnrelatedCalculations = state.data.filter(
                (calculation) =>
                    !calculationIds.includes(calculation.calculationNr)
            )

            return {
                ...state,
                data: [...oldUnrelatedCalculations, ...action.calculations],
                requests: [...oldRequestsThatAreStillValid, action.request],
            }
        }
        default:
            return state
    }
}
