import {
    CoveringLetterWithTtl,
    PaginatedRequest,
    PaginatedRequestWithTtl,
} from '../../types/stateTypes'
import {
    GetCoveringLetterListDataAction,
    GetCoveringLetterListDataFailureAction,
    GetCoveringLetterListDataRequestAction,
    GetCoveringLetterListDataSuccessAction,
    GET_COVERING_LETTER_LIST_DATA,
    GET_COVERING_LETTER_LIST_DATA_FAILURE,
    GET_COVERING_LETTER_LIST_DATA_REQUEST,
    GET_COVERING_LETTER_LIST_DATA_SUCCESS,
} from '../actionTypes/coveringLetterListActionTypes'

export function getCoveringLetterListData(
    request: PaginatedRequest
): GetCoveringLetterListDataAction {
    return {
        type: GET_COVERING_LETTER_LIST_DATA,
        request,
    }
}

export function getCoveringLetterListDataRequest(
    request: PaginatedRequest
): GetCoveringLetterListDataRequestAction {
    return {
        type: GET_COVERING_LETTER_LIST_DATA_REQUEST,
        request,
    }
}

export function getCoveringLetterListDataSuccess(
    coveringLetters: CoveringLetterWithTtl[],
    request: PaginatedRequestWithTtl
): GetCoveringLetterListDataSuccessAction {
    return {
        type: GET_COVERING_LETTER_LIST_DATA_SUCCESS,
        coveringLetters,
        request,
    }
}

export function getCoveringLetterListDataFailure(
    error: Error | string
): GetCoveringLetterListDataFailureAction {
    return {
        type: GET_COVERING_LETTER_LIST_DATA_FAILURE,
        error,
    }
}
