import { CoveringLetterState } from '../../types/stateTypes'
import { DataType, shouldDataBeUpdated } from '../../utils/date'
import {
    CoveringLetterListDataAction,
    GET_COVERING_LETTER_LIST_DATA_SUCCESS,
} from '../actionTypes/coveringLetterListActionTypes'

const initialState: CoveringLetterState = { data: [], requests: [] }

export default function customerReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: CoveringLetterState = initialState,
    action: CoveringLetterListDataAction
): CoveringLetterState {
    switch (action.type) {
        case GET_COVERING_LETTER_LIST_DATA_SUCCESS: {
            const coveringLetterIds = action.coveringLetters.map(
                (coveringLetter) => coveringLetter.coveringLetterNr
            )

            const oldRequestsThatAreStillValid = state.requests
                ? state.requests.filter(
                      (request) =>
                          !shouldDataBeUpdated(
                              DataType.CoveringLetterData,
                              request.updated
                          )
                  )
                : []
            const oldUnrelatedCoveringLetters = state.data.filter(
                (coveringLetter) =>
                    !coveringLetterIds.includes(coveringLetter.coveringLetterNr)
            )

            return {
                ...state,
                data: [
                    ...oldUnrelatedCoveringLetters,
                    ...action.coveringLetters,
                ],
                requests: [...oldRequestsThatAreStillValid, action.request],
            }
        }
        default:
            return state
    }
}
