import {
    CoveringLetterWithTtl,
    PaginatedRequest,
    PaginatedRequestWithTtl,
} from '../../types/stateTypes'

export const GET_COVERING_LETTER_LIST_DATA =
    'coveringLetterActionTypes/GET_COVERING_LETTER_LIST_DATA'
export interface GetCoveringLetterListDataAction {
    type: typeof GET_COVERING_LETTER_LIST_DATA
    request: PaginatedRequest
}

export const GET_COVERING_LETTER_LIST_DATA_REQUEST =
    'coveringLetterActionTypes/GET_COVERING_LETTER_LIST_DATA_REQUEST'
export interface GetCoveringLetterListDataRequestAction {
    type: typeof GET_COVERING_LETTER_LIST_DATA_REQUEST
    request: PaginatedRequest
}

export const GET_COVERING_LETTER_LIST_DATA_SUCCESS =
    'coveringLetterActionTypes/GET_COVERING_LETTER_LIST_DATA_SUCCESS'
export interface GetCoveringLetterListDataSuccessAction {
    type: typeof GET_COVERING_LETTER_LIST_DATA_SUCCESS
    coveringLetters: CoveringLetterWithTtl[]
    request: PaginatedRequestWithTtl
}

export const GET_COVERING_LETTER_LIST_DATA_FAILURE =
    'coveringLetterActionTypes/GET_COVERING_LETTER_LIST_DATA_FAILURE'
export interface GetCoveringLetterListDataFailureAction {
    type: typeof GET_COVERING_LETTER_LIST_DATA_FAILURE
    error: Error | string
}

export type CoveringLetterListDataAction =
    | GetCoveringLetterListDataAction
    | GetCoveringLetterListDataRequestAction
    | GetCoveringLetterListDataSuccessAction
    | GetCoveringLetterListDataFailureAction
