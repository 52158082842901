import React from 'react'
import { AppShell, Grid, Header, Space } from '@mantine/core'
import { Outlet } from 'react-router-dom'
import Navigation from './components/Navigation'
import ChooseLanguageDropdown from './components/ChooseLanguageDropdown'

const App = () => (
    <AppShell
        header={
            <Header height="3rem">
                <Grid>
                    <Grid.Col span={11}>
                        <Space />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <ChooseLanguageDropdown />
                    </Grid.Col>
                </Grid>
            </Header>
        }
        navbar={<Navigation />}
    >
        <Outlet />
    </AppShell>
)

export default App
