import {
    CalculationWithTtl,
    PaginatedRequest,
    PaginatedRequestWithTtl,
} from '../../types/stateTypes'

export const GET_CALCULATION_LIST_DATA =
    'calculationActionTypes/GET_CALCULATION_LIST_DATA'
export interface GetCalculationListDataAction {
    type: typeof GET_CALCULATION_LIST_DATA
    request: PaginatedRequest
}

export const GET_CALCULATION_LIST_DATA_REQUEST =
    'calculationActionTypes/GET_CALCULATION_LIST_DATA_REQUEST'
export interface GetCalculationListDataRequestAction {
    type: typeof GET_CALCULATION_LIST_DATA_REQUEST
    request: PaginatedRequest
}

export const GET_CALCULATION_LIST_DATA_SUCCESS =
    'calculationActionTypes/GET_CALCULATION_LIST_DATA_SUCCESS'
export interface GetCalculationListDataSuccessAction {
    type: typeof GET_CALCULATION_LIST_DATA_SUCCESS
    calculations: CalculationWithTtl[]
    request: PaginatedRequestWithTtl
}

export const GET_CALCULATION_LIST_DATA_FAILURE =
    'calculationActionTypes/GET_CALCULATION_LIST_DATA_FAILURE'
export interface GetCalculationListDataFailureAction {
    type: typeof GET_CALCULATION_LIST_DATA_FAILURE
    error: Error | string
}

export type CalculationListDataAction =
    | GetCalculationListDataAction
    | GetCalculationListDataRequestAction
    | GetCalculationListDataSuccessAction
    | GetCalculationListDataFailureAction
