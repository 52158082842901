import { put, call, all, fork, select, takeEvery } from 'redux-saga/effects'

import { fetchCustomerData } from '../services/customerService'
import {
    GetCustomerDataAction,
    GET_CUSTOMER_DATA,
} from '../actionTypes/customerActionTypes'
import {
    getCustomerDataFailure,
    getCustomerDataRequest,
    getCustomerDataSuccess,
} from '../actionCreators/customerActionCreators'
import { CustomerWithTtl, CustomerState } from '../../types/stateTypes'
import { shouldDataBeUpdated, DataType } from '../../utils/date'
import { AppState } from '../reducers/rootReducer'
import { CustomerV1 } from '../../futursoft/generated/warehouse'

export const getExistingData = (
    state: AppState,
    customerId: number
): CustomerWithTtl | undefined =>
    state.customer.data.find((customer) => customerId === customer.customerNr)

export const getAllData = (state: AppState): CustomerState => state.customer

function* onLoadCustomerData(action: GetCustomerDataAction) {
    try {
        const existingCustomerData: CustomerWithTtl | undefined = yield select(
            getExistingData,
            action.customerId
        )

        if (
            !shouldDataBeUpdated(
                DataType.CustomerData,
                existingCustomerData?.updated
            )
        ) {
            return
        }

        yield put(getCustomerDataRequest(action.customerId))
        const customerData: CustomerV1 = yield call(
            fetchCustomerData,
            action.customerId
        )
        const now = new Date()
        yield put(getCustomerDataSuccess({ ...customerData, updated: now }))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error)
        yield put(getCustomerDataFailure(error.response))
    }
}

function* watchOnLoadCustomerdata() {
    yield takeEvery(GET_CUSTOMER_DATA, onLoadCustomerData)
}

export default function* customerdataSaga() {
    yield all([fork(watchOnLoadCustomerdata)])
}
