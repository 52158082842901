import { UsersInGroupDto } from '../../types/dtoTypes'
import {
    GetUserListDataAction,
    GetUserListDataFailureAction,
    GetUserListDataRequestAction,
    GetUserListDataSuccessAction,
    GET_USER_LIST_DATA,
    GET_USER_LIST_DATA_FAILURE,
    GET_USER_LIST_DATA_REQUEST,
    GET_USER_LIST_DATA_SUCCESS,
} from '../actionTypes/adminUserListActionTypes'

export function GetUserListData(): GetUserListDataAction {
    return {
        type: GET_USER_LIST_DATA,
    }
}

export function GetUserListDataRequest(): GetUserListDataRequestAction {
    return {
        type: GET_USER_LIST_DATA_REQUEST,
    }
}

export function GetUserListDataSuccess(
    usersInGroups: UsersInGroupDto[]
): GetUserListDataSuccessAction {
    return {
        type: GET_USER_LIST_DATA_SUCCESS,
        usersInGroups,
    }
}

export function GetUserListDataFailure(
    error: Error | string
): GetUserListDataFailureAction {
    return {
        type: GET_USER_LIST_DATA_FAILURE,
        error,
    }
}
