import React from 'react'
import { Navbar, Space } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import NavigationLink from '../NavigationLink'
import { CustomerWithTtl } from '../../types/stateTypes'

export type NavigationProps = {
    customerIds: number[]
    customers: CustomerWithTtl[]
}

const Navigation = ({ customerIds, customers }: NavigationProps) => {
    const { t } = useTranslation()

    const navbarSections = customerIds.map((customerId) => (
        <div key={customerId}>
            <Navbar.Section>
                <NavigationLink to={`/customer/${customerId}`}>
                    {
                        // This needs proper translation and better way to figure out what to do when we don't have a company name
                        customers.find(
                            (customer) => customer.customerNr === customerId
                        )?.companyName ||
                            customers.find(
                                (customer) => customer.customerNr === customerId
                            )?.name
                    }
                </NavigationLink>
            </Navbar.Section>
            <Navbar.Section>
                <NavigationLink to={`/customer/${customerId}/calculations`}>
                    {t('navigation.calculationList')}
                </NavigationLink>
            </Navbar.Section>
            <Navbar.Section>
                <NavigationLink to={`/customer/${customerId}/expressorders`}>
                    {t('navigation.expressOrders')}
                </NavigationLink>
            </Navbar.Section>
            <Navbar.Section>
                <NavigationLink to={`/customer/${customerId}/coveringletters`}>
                    {t('navigation.coveringLetters')}
                </NavigationLink>
            </Navbar.Section>
            <Navbar.Section>
                <NavigationLink to={`/customer/${customerId}/invoices`}>
                    {t('navigation.invoices')}
                </NavigationLink>
            </Navbar.Section>
            <Space h="md" />
        </div>
    ))

    return (
        <Navbar hiddenBreakpoint="sm" width={{ sm: 200 }}>
            <Navbar.Section>
                <NavigationLink to="/">
                    {t('navigation.frontPage')}
                </NavigationLink>
            </Navbar.Section>
            <Space h="md" />
            {navbarSections}
            <Space h="md" />
            <Navbar.Section>
                <NavigationLink to="/admin/users">
                    {t('navigation.admin.users')}
                </NavigationLink>
            </Navbar.Section>
        </Navbar>
    )
}

export default Navigation
