import { ExpressOrderState } from '../../types/stateTypes'
import { DataType, shouldDataBeUpdated } from '../../utils/date'
import {
    ExpressOrderListDataAction,
    GET_EXPRESS_ORDER_LIST_DATA_SUCCESS,
} from '../actionTypes/expressOrderListActionTypes'

const initialState: ExpressOrderState = { data: [], requests: [] }

export default function customerReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: ExpressOrderState = initialState,
    action: ExpressOrderListDataAction
): ExpressOrderState {
    switch (action.type) {
        case GET_EXPRESS_ORDER_LIST_DATA_SUCCESS: {
            const expressOrderIds = action.expressOrders.map(
                (expressOrder) => expressOrder.expressOrderNr
            )

            const oldRequestsThatAreStillValid = state.requests
                ? state.requests.filter(
                      (request) =>
                          !shouldDataBeUpdated(
                              DataType.CoveringLetterData,
                              request.updated
                          )
                  )
                : []
            const oldUnrelatedExpressOrders = state.data.filter(
                (expressOrder) =>
                    !expressOrderIds.includes(expressOrder.expressOrderNr)
            )

            return {
                ...state,
                data: [...oldUnrelatedExpressOrders, ...action.expressOrders],
                requests: [...oldRequestsThatAreStillValid, action.request],
            }
        }
        default:
            return state
    }
}
