import { all } from 'redux-saga/effects'
import UserSaga from './userSaga'
import CustomerSage from './customerSaga'
import InvoiceSage from './invoiceSaga'
import CoveringLetterSaga from './coveringLetterSaga'
import CalculationSaga from './calculationSaga'
import ExpressOrderSaga from './expressOrderSaga'
import AdminSaga from './adminSaga'

export default function* rootSaga() {
    yield all([
        UserSaga(),
        CustomerSage(),
        InvoiceSage(),
        CoveringLetterSaga(),
        CalculationSaga(),
        ExpressOrderSaga(),
        AdminSaga(),
    ])
}
