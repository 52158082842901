import { UserRole } from '../../types/dtoTypes'
import { UserState } from '../../types/stateTypes'
import {
    GET_USER_DATA_SUCCESS,
    UserdataAction,
} from '../actionTypes/userActionTypes'

const initialState: UserState = {
    role: undefined,
    companies: [],
    updated: undefined,
}

export default function userReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: UserState = initialState,
    action: UserdataAction
): UserState {
    switch (action.type) {
        case GET_USER_DATA_SUCCESS:
            return {
                ...state,
                companies: action.user.companies || [],
                role: action.user.role || UserRole.UNKNOWN,
                updated: action.user.updated,
            }
        default:
            return state
    }
}
