import { CustomerWithTtl } from '../../types/stateTypes'

export const GET_CUSTOMER_DATA = 'customerActionTypes/GET_CUSTOMER_DATA'
export interface GetCustomerDataAction {
    type: typeof GET_CUSTOMER_DATA
    customerId: number
}

export const GET_CUSTOMER_DATA_REQUEST =
    'customerActionTypes/GET_CUSTOMER_DATA_REQUEST'
export interface GetCustomerDataRequestAction {
    type: typeof GET_CUSTOMER_DATA_REQUEST
    customerId: number
}

export const GET_CUSTOMER_DATA_SUCCESS =
    'customerActionTypes/GET_CUSTOMER_DATA_SUCCESS'
export interface GetCustomerDataSuccessAction {
    type: typeof GET_CUSTOMER_DATA_SUCCESS
    customer: CustomerWithTtl
}

export const GET_CUSTOMER_DATA_FAILURE =
    'customerActionTypes/GET_CUSTOMER_DATA_FAILURE'
export interface GetCustomerDataFailureAction {
    type: typeof GET_CUSTOMER_DATA_FAILURE
    error: Error | string
}

export type CustomerDataAction =
    | GetCustomerDataAction
    | GetCustomerDataRequestAction
    | GetCustomerDataSuccessAction
    | GetCustomerDataFailureAction
